/* eslint-disable max-nested-callbacks */
import { CSVLink } from 'react-csv';
import { useAppSelector } from '../../hooks/store';
import {
  nightEndSelector,
  selectedWarehouseSelector,
} from 'src/state/selectors/delivery';
import { GetLocation } from 'src/graphql/types/delivery';
import { getDeliveryWindowStr } from 'src/utils/date';
import { Button } from '@odekoteam/doppio';

const headers = [
  { label: 'location_id', key: 'locationId' },
  { label: 'business_name', key: 'businessName' },
  { label: 'delivery_window', key: 'deliveryWindow' },
  { label: 'city', key: 'city' },
  { label: 'state', key: 'state' },
  { label: 'postal_code', key: 'postalCode' },
  { label: 'cohort', key: 'cohort' },
  { label: 'automated_routing', key: 'automatedRouting' },
];

type CsvRow = {
  locationId: string;
  businessName: string;
  deliveryWindow: string;
  city: string;
  state: string;
  postalCode: string;
  cohort?: string;
  automatedRouting: string;
};

const DownloadLocationsCsv = ({
  locations,
  loading,
}: {
  locations: GetLocation[];
  loading: boolean;
}): JSX.Element => {
  const nightEnd = useAppSelector(nightEndSelector);
  const selectedWarehouse = useAppSelector(selectedWarehouseSelector);
  const isDisabled = loading;
  const csvData: CsvRow[] = locations.map((location) => {
    const deliveryWindowLabel = getDeliveryWindowStr({
      locationDeliveryWindow: location.locationDeliveryWindow,
      showDate: false,
    });
    return {
      locationId: location.id,
      businessName: location.businessName,
      deliveryWindow: deliveryWindowLabel,
      city: location.address.city,
      state: location.address.state,
      postalCode: location.address.postalCode,
      cohort: location.routingCohort?.name,
      automatedRouting: location.includeInAutomatedRouting ? 'true' : 'false',
    };
  });

  return (
    <Button id="download-csv-button" isDisabled={isDisabled}>
      {loading ? (
        <div>Loading CSV...</div>
      ) : (
        <CSVLink
          data={csvData}
          headers={headers}
          filename={`locations_${nightEnd.viewing}_${selectedWarehouse?.name}.csv`}
          style={{
            color: 'black',
            cursor: isDisabled ? 'not-allowed' : 'pointer',
            textDecoration: 'none',
          }}
        >
          Download CSV
        </CSVLink>
      )}
    </Button>
  );
};

export default DownloadLocationsCsv;
