import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { client } from 'src/graphql/client';
import { GET_ORDERS } from 'src/graphql/requests/getOrders';
import { GetOrders } from 'src/graphql/types/delivery';
import { GGetOrdersQuery } from 'src/graphql/types/generated';
import { DeliveryState } from './state';

type getThirdPartyOrdersArgs = {
  warehouseId: string;
  nightEnd: string;
  cached?: true;
};
export const getThirdPartyOrders = createAsyncThunk(
  'delivery/thirdPartyOrders',
  async ({
    warehouseId,
    nightEnd,
  }: getThirdPartyOrdersArgs): Promise<GetOrders | null> => {
    const { data, error } = await client
      .query<GGetOrdersQuery>(
        GET_ORDERS,
        {
          input: {
            warehouseId: warehouseId,
            deliveryMethods: ['roadie_delivery', 'roadie_crossdock'],
            deliveryDate: nightEnd,
          },
          first: 100,
          after: null,
        },
        { requestPolicy: 'cache-and-network' },
      )
      .toPromise();
    if (error || !data?.orders) {
      // TODO: do something with the error
      console.error('no third party orders returned', error);
      return null;
    }
    return data.orders;
  },
);

export const getThirdPartyOrdersFulfilled = (
  state: DeliveryState,
  action: PayloadAction<GetOrders | null>,
): void => {
  state.thirdPartyOrders = action.payload ? { ...action.payload } : undefined;
  state.thirdPartyOrdersLoading = false;
};

export const getThirdPartyOrdersPending = (
  state: DeliveryState,
  { meta: { arg } }: { meta: { arg: getThirdPartyOrdersArgs } },
): void => {
  // Disable routes sync button if we request routes
  state.thirdPartyOrdersLoading = arg.cached ? false : true;
};

export const getThirdPartyOrdersRejected = (state: DeliveryState): void => {
  state.thirdPartyOrdersLoading = false;
  // TODO
};
