// this util is referenced in cypress/
// absolute references don't work in the cypress folder

import { Tense } from '../graphql/types/delivery';
import { DeliveryState } from '../state/slices/delivery';
import { getSelectedWarehouse } from '.';
import { zonedTimeToUtc } from 'date-fns-tz';

// moving this to it own file to avoid tests breaking
export const getModifiedIsoDate = (
  isoDate: string,
  isoTime?: string,
): string => {
  // (2020-08-10T12:00PM, 6:00PM) -> 2020-08-10T6:00PM
  if (isoTime) {
    return `${isoDate.split('T')[0]}T${isoTime}`;
  }
  return isoDate.split('T')[0];
};

export const isSameDayDeliveryWindow = (startTime = ''): boolean => {
  const SUBSTRING = 'PM';
  const time = formatTime24To12Hour(startTime);
  return !time.includes(SUBSTRING);
};

export const isDateTimeAfter = ({
  deliveredAt,
  endTime,
  viewingDate,
  timeZone,
}: {
  deliveredAt: string;
  endTime: string;
  viewingDate: string;
  timeZone?: string;
}): boolean => {
  if (!timeZone) return false;
  const endMs = zonedTimeToUtc(
    new Date(`${viewingDate} ${endTime}`),
    timeZone,
  ).getTime();
  const deliveredMs = Date.parse(deliveredAt);
  return deliveredMs > endMs;
};

export const padToTwo = (num: string): string => {
  return num.padStart(2, '0');
};

/**
 * This is used to make sure when we pass the raw date YYYY-MM-DD into the new Date(date)
 * function it doesn't convert it to a past date due to your timezone
 * i.e. without this function if you use `new Date("2022-09-01")` in PST it will
 * return a date of `Wed Aug 31 2022 17:00:00 GMT-0700 (Pacific Daylight Time)`
 * but if you use `adjustForTimezone(new Date("2022-09-01"))` in PST it will
 * return `Thu Sep 01 2022 00:00:00 GMT-0700 (Pacific Daylight Time)`
 * @param  {Date} date Date object
 * @returns new Date object
 */
export const adjustForTimezone = (date: Date): Date => {
  const timeOffsetInMS: number = date.getTimezoneOffset() * 60000;
  date.setTime(date.getTime() + timeOffsetInMS);
  return date;
};

export const getYearMonthDay = (date: Date): string => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const monthStr = month > 9 ? month : `0${month}`;
  const day = date.getDate();
  const dayStr = day > 9 ? day : `0${day}`;
  return `${year}-${monthStr}-${dayStr}`;
};

export const formatISO8601DateTime = (
  timestamp: string,
  timeZone?: string,
): string => {
  const dateTime = Date.parse(timestamp);

  // [MMM] [DoM], [HH]:[mm] [meridiem] [TZ]
  return Intl.DateTimeFormat('en-US', {
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    timeZone: timeZone,
    timeZoneName: 'short',
  }).format(dateTime);
};

// formats time from military to regular eg. "18:00" -> "6:00PM"
export const formatTime24To12Hour = (time: String): String => {
  const timeString12hr = new Date(
    '1970-01-01T' + time + 'Z',
  ).toLocaleTimeString('en-US', {
    timeZone: 'UTC',
    hour12: true,
    hour: 'numeric',
    minute: 'numeric',
  });
  return timeString12hr;
};

export const addDays = (days: number, currentDate: Date): Date => {
  let futureDate: Date = currentDate;
  futureDate.setDate(currentDate.getDate() + days);
  return futureDate;
};

// returns now in UTC as YYYY-MM-DDThh:mm:ss.sss+00:00 ex. 2022-09-13T20:57:36.070+00:00
export const getRubyFriendlyUtcStr = (): string => {
  const strToReplace = 'Z';
  const appendUTCStr = '+00:00';
  const now = new Date(Date.now());
  const utc = now.toISOString();
  const rubyFriendlyUtc = utc.replace(strToReplace, appendUTCStr);
  return rubyFriendlyUtc;
};

export const getDateStr = (isoDate: string): string => {
  const date = adjustForTimezone(new Date(isoDate));
  const month = date.toLocaleString('default', { month: 'long' });
  const day = date.getDate();
  const year = date.getFullYear();
  return `${month} ${day}, ${year}`;
};

// determine if the user is in the past present or future based on the warehouse's night end.
export const pastPresentFutureDate = (
  delivery: DeliveryState,
  nightEnd?: string,
): Tense | undefined => {
  if (!nightEnd) return undefined;
  const selectedWarehouse = getSelectedWarehouse(
    delivery.selectedWarehouseId,
    delivery.warehouses,
  );
  if (!selectedWarehouse) return undefined;

  const todayStr = selectedWarehouse.currentNightEnd
    .split('T')[0]
    .replaceAll('-', '/');
  const today = new Date(todayStr);
  const viewing = adjustForTimezone(new Date(nightEnd));
  if (new Date(today) > new Date(viewing)) return Tense.past;
  if (new Date(today) < new Date(viewing)) return Tense.future;
  return Tense.present;
};

export const isDateBeforeCurrentWarehouseNightEnd = (
  delivery: DeliveryState,
  date: Date,
): boolean => {
  const selectedWarehouse = getSelectedWarehouse(
    delivery.selectedWarehouseId,
    delivery.warehouses,
  );
  if (!selectedWarehouse) return false;

  const currentWarehouseNightEnd = new Date(selectedWarehouse.currentNightEnd);

  return date < currentWarehouseNightEnd;
};

export const getHHMMSSTimeFromDateString = (
  dateString: string,
  timeZone: string,
): string | undefined => {
  const timestamp = Date.parse(dateString);
  if (isNaN(timestamp)) return undefined;

  return Intl.DateTimeFormat('en-US-u-hc-h23', {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
    timeZone: timeZone,
  }).format(timestamp);
};

export const addPlusOneDayStringToPMTime = (startTime?: string): String => {
  if (!startTime) return '';
  return isSameDayDeliveryWindow(startTime) ? '' : ' (+1 day)';
};

export const getDeliveryWindowStr = ({
  locationDeliveryWindow,
  showDate,
  viewingNightEnd,
}: {
  locationDeliveryWindow: {
    startTime: string;
    endTime: string;
  };
  showDate: boolean;
  viewingNightEnd?: string;
}): string => {
  const deliveryStartTime = formatTime24To12Hour(
    locationDeliveryWindow.startTime ?? '',
  );
  const deliveryEndTime = formatTime24To12Hour(
    locationDeliveryWindow.endTime ?? '',
  );
  const plusOneDayString = addPlusOneDayStringToPMTime(
    locationDeliveryWindow.startTime,
  );

  let previousDateStr: string;
  if (viewingNightEnd) {
    const previousDate = adjustForTimezone(
      addDays(-1, new Date(viewingNightEnd)),
    );
    previousDateStr = getYearMonthDay(previousDate);
  }

  const getFormatStartDate = (): string => {
    if (isSameDayDeliveryWindow(locationDeliveryWindow.startTime))
      return ` (${viewingNightEnd})`;
    return ` (${previousDateStr})`;
  };

  const formatStartDate = showDate ? getFormatStartDate() : '';
  const formatEndDate = showDate ? ` (${viewingNightEnd})` : '';
  const deliveryWindowLabel = `${deliveryStartTime}${formatStartDate} - ${deliveryEndTime}${plusOneDayString}${formatEndDate}`;
  return deliveryWindowLabel;
};
