import { gql } from 'urql';
import { PRODUCT } from '../client';

export const GET_ORDERS = gql`
  query ${PRODUCT}GetOrders($input: OrdersInput!, $first: Int, $after: String) {
    orders(input: $input, first: $first, after: $after) {
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
      nodes {
        id
        orderNumber
        dueAt
        deliveryMethod
        location {
          id
          businessName
          locationDeliveryWindow {
            endTime
            startTime
          }
          address {
            latitude
            longitude
            addressLine1
            addressLine2
            city
            postalCode
            state
          }
        }
        vendor {
          isWarehouse
        }
        externalDelivery {
          id
          status
          trackingUrl
          estimatedPickupTime
          estimatedDeliveryTime
          containerCount
        }
        orderLines {
          id
          sku {
            id
            refrigerationState
          }
        }
      }
    }
  }
`;
