import { getDeliveryWindowStr, isSameDayDeliveryWindow } from 'src/utils/date';
import { Tooltip } from '@odekoteam/doppio';
import { Flex, ThemeUIStyleObject } from 'theme-ui';
import { nightEndSelector } from 'src/state/selectors/delivery';
import { useAppSelector } from 'src/hooks/store';
import DaytimeIcon from './Icons/DaytimeIcon';
import NighttimeIcon from './Icons/NighttimeIcon';

const smallLabel: ThemeUIStyleObject = {
  fontFamily: 'body',
  variant: 'text.labelSm',
  color: 'secondaryText',
  overflow: 'auto',
};

const svgWrapper: ThemeUIStyleObject = {
  display: 'flex',
  marginRight: '8px',
};

const DeliveryWindow = ({
  locationDeliveryWindow,
  id,
  style = {},
  showIcon = true,
  labelStyle,
  showDate = false,
}: {
  locationDeliveryWindow?: {
    startTime: string;
    endTime: string;
  };
  id: string;
  style?: ThemeUIStyleObject;
  showIcon?: Boolean;
  labelStyle?: ThemeUIStyleObject;
  showDate?: boolean;
}): JSX.Element | null => {
  const nightEnd = useAppSelector(nightEndSelector);

  if (!locationDeliveryWindow) return null;

  const deliveryWindowLabel = getDeliveryWindowStr({
    locationDeliveryWindow,
    showDate,
    viewingNightEnd: nightEnd.viewing,
  });
  const icon = isSameDayDeliveryWindow(locationDeliveryWindow.startTime) ? (
    <DaytimeIcon />
  ) : (
    <NighttimeIcon />
  );

  return (
    <Flex sx={{ alignItems: 'center', ...style }} id={`deliveryWindow-${id}`}>
      {showIcon && (
        <Tooltip labelText="Delivery Window" placement="top">
          <div sx={svgWrapper}>{icon}</div>
        </Tooltip>
      )}
      <span sx={labelStyle ? { ...smallLabel, ...labelStyle } : smallLabel}>
        {deliveryWindowLabel}
      </span>
    </Flex>
  );
};

export default DeliveryWindow;
